<template>
  <div>
    <div class="fb-style business-background firstSectionPadFix">
      <div class="fb-grid">
        <div class="fb-content">
          <h1>Business Intelligence</h1>
          <h3>
            Track your data, and turn that data into understandable insights.
          </h3>
          <router-link to="/contact"
            ><button class="fb-content-btn">Start tracking</button></router-link
          >
        </div>
        <img src="@/assets/images/icon_business-inteligence_outline.svg" />
      </div>
    </div>

    <div class="label labelColorGrey">
      <div class="hrdiv hrdiv-grey-color"></div>
      <div class="pdiv">
        <p>&nbsp;Website development</p>
      </div>
    </div>

    <div class="SecondBannerWrapper labelColorGrey">
      <div class="gridSBW">
        <div class="FirstWrap">
          <div class="wrap">
            <h2>Wordpress</h2>
            <p>
              Wordpress is the most used system for website building. It's
              stability and ecosystem makes it great for customization of any
              sort of website, such as sales of services, brand pages
            </p>
          </div>
          <div class="wrap">
            <h2>Shopify</h2>
            <p>
              Shopify is specialized in e-commerce, and is the most used
              platform for online stores in the world. It also holds features to
              integrate its point of sale with its online system, increasing the
              usability for any store that also does commerce online.
            </p>
          </div>
          <div class="wrap banner">
            <h2>
              <p>
                <div class="banner">
                <span>Our developers have created</span
                ><span>hundreds of websites</span>
                <span>through these two systems.</span>
                </div>
              </p>
            </h2>
          </div>
          <div class="wrap wrap-btn">
            <router-link to="/contact"
              ><button class="blk btn">Build your website</button></router-link
            >
          </div>
        </div>

        <div class="SecondWrap">
          <h2>Platforms we excel in:</h2>
          <div class="platforms">
            <ul>
              <li>WordPress</li>
              <li>Shopify</li>
              <li>Shopify+</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <MainWhatWeDo />
    <Testimonials />
    <div class="who-we-are">
    <WhoWeAre />
    </div>
    <div class="trustedby"></div>
    <Trustedby />
  </div>
</template>

<script>
import Testimonials from "../components/Testimonials.vue";
import MainWhatWeDo from "../components/MainWhatWeDo.vue";
import WhoWeAre from "./WhoWeAre.vue";
import Trustedby from "../components/Trustedby.vue";
export default {
  name: "Business",
  title: "Business Intelligence",
  components: { Testimonials, MainWhatWeDo, WhoWeAre, Trustedby },
};
</script>

<style scoped>
.trustedby {
  background: #f8f8f8;
}


@import "../assets/css/web.css";
@import "../assets/css/tablet.css";
@import "../assets/css/mobile.css";
@media screen and (max-width: 750px) {
  .banner{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .banner > span{
    font-family: "Helvetica bold";
    font-size: 5.5vw;
    line-height: 6.3vw;
    padding-top: 1vw;
    height: max-content;
    min-height: calc(2.1vw * 2);
  }
}
</style>
